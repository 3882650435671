import React from "react"
import Helmet from "react-helmet"
export default () =>
    <Helmet>
<script type="application/ld+json">{`{
"@context": "http://schema.org",
"@type": "WebSite",
"name": "Automaalaamo Auto & Muovi Fix Bilmåleri",
"url": "https://www.automuovifix.fi"
}`}
</script>
<script type="application/ld+json">
{`{
	"@context": "https://schema.org",
	"@type": "AutoRepair",
	"name": "Automaalaamo Auto & Muovi Fix Bilmåleri",
	"address": {
		"@type": "PostalAddress",
		"streetAddress": "Kauppatie 16",
		"addressRegion": "Mustasaari",
		"postalCode": "65610"
        },
	"image": "https://www.automuovifix.fi/static/auto-icon-98x148-e55aeeb30e19ad96f701c721cd1998bc.png",
	"email": "kaj@howtos.tips",
	"url": "https://www.automuovifix.fi",
	"telephone": "+358414674200",
	"@id": "https://www.automuovifix.fi",
	"paymentAccepted": [ "cash", "invoice" ],
	"openingHours": "Mo,Tu,We,Th,Fr 08:00-16:00",
	"openingHoursSpecification": [ {
		"@type": "OpeningHoursSpecification",
		"dayOfWeek": [
			"Monday",
			"Tuesday",
			"Wednesday",
			"Thursday",
			"Friday"
		],
		"opens": "08:00",
		"closes": "16:00"
	} ],
	"geo": {
		"@type": "GeoCoordinates",
		"latitude": "63.129979",
		"longitude": "21.737042"
	},
	"priceRange":"$$$",
    "sameAs": "https://www.instagram.com/automuovifix"
}`}
</script>
</Helmet>